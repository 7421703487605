@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
    word-break: break-word !important;
} */

.flag-select {
  padding-bottom: 0 !important;
}

.flag-select ul {
  min-width: 295px !important;
}

.flag-select button {
  border: 0px;
  /* background: #d1d5db; */
  border-radius: 10px;
  min-height: 40px;
  --tw-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  --tw-shadow-colored: 0px 3px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.flag-select button:hover,
.flag-select button:focus {
  background: #d1d5db;
  outline: none;
}

.flag-select button {
  padding-right: 5px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.flag-select button span {
  padding-right: 0;
}

.flag-select-viewOnly button::after {
  content: "";
  display: none;
  width: 0;
  border: none;
}

.flag-select-viewOnly button {
  border: 0px;
  background: #ffffff;
  border-radius: 10px;
  min-height: 40px;
  padding: 0;
}

.flag-select-viewOnly .ReactFlagsSelect-module_selectValue__152eS {
  margin: 0;
  padding: 0;
}

[data-tooltip] {
  position: relative;
  border-bottom: 1px dashed #000;
  cursor: help;
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: 0;
  top: calc(100% + 10px);
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.6);
  background-color: white;
  z-index: 10;
  padding: 8px;
  width: 300px;
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}
